{
  "name": "rt-app",
  "version": "2.5.2-27be9c62.0",
  "scripts": {
    "build": "./scripts/version.sh && ng build",
    "install": "cp ./package.json ./src/assets",
    "lint": "ng lint",
    "ng": "ng",
    "start": "cp ./package.json ./src/assets && ng serve --ssl --host localhost.sparbanken.me",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.8",
    "@angular/cdk": "^17.0.4",
    "@angular/common": "^17.0.8",
    "@angular/compiler": "^17.0.8",
    "@angular/core": "^17.0.8",
    "@angular/forms": "^17.0.8",
    "@angular/material": "^17.0.4",
    "@angular/platform-browser": "^17.0.8",
    "@angular/platform-browser-dynamic": "^17.0.8",
    "@angular/router": "^17.0.8",
    "@swimlane/ngx-charts": "^20.5.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.8",
    "@angular-eslint/builder": "17.1.1",
    "@angular-eslint/eslint-plugin": "17.1.1",
    "@angular-eslint/eslint-plugin-template": "17.1.1",
    "@angular-eslint/schematics": "17.1.1",
    "@angular-eslint/template-parser": "17.1.1",
    "@angular/cli": "^17.0.8",
    "@angular/compiler-cli": "^17.0.8",
    "@angular/material-luxon-adapter": "^17.0.4",
    "@sparbanken-syd/personnummer": "^2.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.0.5",
    "@types/d3": "^7.4.3",
    "@types/jasmine": "~5.1.4",
    "@types/luxon": "^3.3.7",
    "@types/node": "18",
    "@typescript-eslint/eslint-plugin": "^6.17.0",
    "@typescript-eslint/parser": "^6.17.0",
    "eslint": "^8.56.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^47.0.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.4",
    "postcss": "^8.4.32",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.1.0",
    "stylelint-config-standard-scss": "^12.0.0",
    "stylelint-scss": "^6.0.0",
    "typescript": "5.2.2"
  }
}
